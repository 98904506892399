// Blocks & Elements
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.input__wrapper {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;
  &.row {
    flex-direction: row;
    align-items: center;
    > div {
      flex: 1 1 0;
      justify-content: start;
    }
  }

  &.__error {
    .label__area {
      color: $c-danger !important;
    }
    input {
      background-color: $c-danger-background !important;
      &::placeholder {
        color: $c-danger !important;
      }
    }
  }

  &--colorPicker {
    height: 40px;
    background-color: $c-background;
    border-radius: 8px;
    border: none;
    color: $c-text;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;

    span {
      color: $c-gray;
      font-size: 14px;
    }
  }

  &--field {
    width: 100%;
  }

  & input {
    height: 40px;
    background-color: $c-background;
    border-radius: 8px;
    border: none;
    color: $c-text;
    font-weight: 400;
    font-size: clamp(12px, 3vw, 14px);

    &:hover,
    &:focus {
      box-shadow: none;
      background-color: $c-background;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $c-gray;
    }
  }

  & textarea {
    background-color: $c-background;
    border-radius: 8px;
    border: none;
    color: $c-text;
    font-weight: 400;
    font-size: 16px;

    &:hover,
    &:focus {
      box-shadow: none;
      background-color: $c-background;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $c-gray;
    }
  }

  .input-errors {
    color: $c-red;
    font-size: clamp(12px, 3vw, 14px);
    font-weight: 400;
    margin: 8px 0;
  }

  & .ant-input-affix-wrapper {
    background-color: $c-background;
    border: none !important;
    padding: 0 8px 0 0 !important;

    input {
      padding: 0 8px;
      border-radius: 8px;

      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: $c-gray;
      }

      &:focus {
        box-shadow: none;
        background-color: $c-background;
      }
    }
  }

  & input:-webkit-autofill {
    background-color: $c-background !important;
    transition: background-color 5000s ease-in-out 0s;
  }

  & input:-moz-autofill {
    background-color: $c-background !important;
  }
}

.switch__wrapper {
  display: flex;
  gap: 16px;
  & .label__area {
    flex: 1;
    justify-content: flex-start;
  }
}

.input__second {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  flex-direction: column;

  &.__error {
    .label__area {
      color: $c-danger !important;
    }
    input {
      background: rgba(242, 98, 98, 0.1) !important;
      &::placeholder {
        color: $c-danger !important;
      }
    }
  }

  &--field {
    width: 100%;
  }

  .ant-input-affix-wrapper {
    height: 40px;
  }

  & input {
    height: 32px;
    background-color: $c-white;
    border-radius: 8px;
    border: 1px solid $c-gray;
    color: $c-text;
    font-weight: 400;
    font-size: 16px;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $c-gray;
    }
  }

  .input-errors {
    color: $c-red;
    font-size: clamp(12px, 3vw, 14px);
    font-weight: 400;
    margin: 8px 0;
  }
}

.textarea__custom {
  background-color: $c-background;
  border-radius: 8px;
  border: none;
  color: $c-text;
  font-weight: 400;
  font-size: 16px;
}

.datePicker-hint-text {
  text-align: center;
  font-size: 12px;
  color: $c-white;
  border-bottom: 1px solid $c-border;
  line-height: 1.2;
  padding: 8px 12px;
  position: absolute;
  top: 0;
  transform: translateY(calc(-100% - 55px));
  background-color: $c-grey-33;
  border-radius: 24px;
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid $c-grey-33;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
  }
}

.datepicker__wrapper {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;
  &.row {
    flex-direction: row;
    align-items: center;
    > div {
      flex: 1 1 0;
      justify-content: start;
    }
  }

  &.__error {
    .label__area {
      color: $c-danger !important;
    }
    .ant-picker {
      background-color: rgba($c-danger, 0.1) !important;
    }
    input {
      background: transparent !important;
      &::placeholder {
        color: $c-danger !important;
      }
    }
  }

  &--field {
    width: 100%;
  }

  & .ant-picker {
    height: 40px;
    width: 100%;
    background-color: $c-background;
    border-radius: 8px;
    border: none !important;
    color: $c-text;
    font-weight: 600;
    font-size: 16px;

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $c-gray;
    }
  }
  .ant-picker-focused {
    box-shadow: none !important;
  }
}

.input__search {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;

  & input {
    height: 40px;
    background-color: $c-background;
    border-radius: 8px;
    border: none !important;
    color: $c-text;
    font-weight: 600;
    font-size: 16px;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $c-gray;
    }
  }

  .ant-input-group-addon {
    background-color: $c-background;
    border: none !important;
    border-left-color: transparent;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;

    .ant-btn {
      background-color: $c-background;
      border: none;
      height: 40px;
      width: 40px;

      & > * {
        color: $c-gray;
      }
    }
  }
}

.number--input {
  .ant-input-affix-wrapper {
    padding: 0 12px;
    background-color: $c-white;
    border-radius: 4px;
    border: 1px solid $c-gray;
    align-items: center;
    & input {
      height: 32px;
      background-color: $c-white;
    }
    & .ant-input-suffix {
      color: $c-text-sec;
      height: 16px;
      border-left: 1px solid $c-text-sec;
      padding-left: 12px;
    }
  }
}

.default--background {
  .ant-input-affix-wrapper {
    padding: 0 12px;
    align-items: center;
    background: $c-background;
    border-radius: 8px;
    border: 1px solid $c-gray;
    align-items: center;
    & input {
      height: 40px;
      background-color: $c-background;
    }
    & .ant-input-suffix {
      color: $c-text-sec;
      height: 16px;
      border-left: none;
      padding-left: 12px;
    }
  }
}

// Modifier
.no--label {
  width: 100% !important;
}
.input--errors {
  color: $c-red;
  font-size: clamp(12px, 3vw, 14px);
  font-weight: 400;
  margin: 8px 0;
}

.input--borderd--red {
  background: $c-danger-background !important;
  &::placeholder {
    color: $c-danger !important;
  }
}
.disabled--input {
  color: $c-gray !important;
}

.full--w {
  width: 100%;
}

// Old
.input-group {
  display: flex;
  &--horizental > div {
    flex: 1 1 0%;
  }
  &__content {
    display: flex;
    align-items: center;
    &--icon {
      margin-right: 8px;
    }
    &--label {
      font-weight: 600;
    }
  }
  &__input {
    display: flex;
    flex-direction: column;
    &--item {
      border-radius: 4px !important;
      padding: 0 8px !important;
      background: #eff3f8 !important;
      border-color: #e8e8e8 !important;
      height: 40px;
      &.dark {
        background: #fff !important;
        border-color: rgb(226, 226, 226) !important;
      }
    }
    &--error {
      font-weight: 500;
      color: red;
    }
  }
}

.input--transparent {
  border-radius: 4px;
  &.top {
    display: grid;
    gap: 8px;
  }

  &.bottom {
    display: flex;
    flex-direction: column-reverse;
    gap: 8px;
  }

  & input {
    &:focus {
      box-shadow: none;
    }
    color: $c-dark-3;
    font-weight: 400;
    font-size: 12px;

    &::placeholder {
      font-weight: 400;
      font-size: 12px;
      color: $c-gray;
    }
  }

  & .label {
    font-weight: 400;
    font-size: 12px;
    color: $c-dark-3;
  }

  .ant-picker {
    width: 100% !important;
  }

  & .ant-input-affix-wrapper,
  input {
    border: 1px solid $c-gray-light4;
    background: $c-white;
    border-radius: 4px;
    width: 100%;
  }

  & .ant-input-suffix {
    color: $c-dark-3;
    font-weight: 400;
    font-size: 12px;
  }
}

.input--group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 991px) {
    width: 100%;
  }

  label {
    padding-left: 24px;
    margin-bottom: 8px;
    font-size: 14px;
    color: $c-forth;
  }

  &--content {
    background-color: $c-white;
    border: 1px solid $c-blue-light;
    padding: 12px 8px;
    border-radius: 8px;

    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    @media (max-width: 991px) {
      width: 100%;
    }
    input {
      border: none;
      outline: none;
      &:focus {
        box-shadow: none;
      }
      font-size: 32px;
      font-weight: 700;
      color: $c-white;

      &::placeholder {
        font-size: 32px;
        font-weight: 700;
        color: $c-gray;
      }
    }

    span {
      padding-top: 4px;
      padding-left: 16px;
      color: $c-gray;
    }
  }
}

.radio-group--wrapper {
  & .ant-radio-group {
    display: flex;
    background: $c-gray-light6;
    border-radius: 4px;

    & .ant-radio-button-wrapper {
      flex: 1;
      text-align: center;
    }

    & .ant-radio-button-wrapper {
      background: transparent;
      color: $c-gray-light4;
      border: none;

      &::before {
        background: transparent;
      }

      &-checked {
        color: $c-blue-light2;
        background: $c-white;
        border: 1px solid $c-blue-light2;
        border-radius: 4px;
        color: $c-dark-2;

        &::before {
          background: transparent;
        }
      }
    }
  }
}

.datepicker--wrapper {
  & .ant-picker {
    width: 100%;
  }
}

.checkbox__wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  label {
    cursor: pointer;
  }
  input[type="checkbox"] {
    cursor: pointer;
    min-width: 16px;
    min-height: 16px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid $c-grey-b9;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
    position: relative;
  }

  input[type="checkbox"]::before {
    content: "";
    color: $c-primary;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    width: 50%;
    height: 50%;
    transition: opacity 0.3s;
    background-image: url("../../assets/icons/check_small.svg"); /* Replace with the path to your custom SVG icon */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  input[type="checkbox"]:checked {
    &:before {
      opacity: 1;
    }
    border: 1px solid $c-primary;
    background-color: rgba($c-primary, 0.1);
  }
}

// Disable
.ant-select-disabled {
  .ant-select-selector {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}

.ant-picker-disabled,
.ant-input-disabled {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.label__required {
  color: $c-danger;
}

.ant-picker-month-btn,
.ant-picker-cell-inner {
  text-transform: capitalize;
}
