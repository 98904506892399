%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

%vc-absolute {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0px auto;
}

%vc-hc-absolute {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0px auto;
}


$width-steps: (
  "auto": auto,
  "0": 0,
  "10": 10%,
  "20": 20%,
  "30": 30%,
  "40": 40%,
  "50": 50%,
  "60": 60%,
  "70": 70%,
  "80": 80%,
  "90": 90%,
  "100": 100%
);

@each $key, $value in $width-steps {
  .w-#{$key} {
    width: #{$value};
  }
}

$height-steps: (
  "auto": auto,
  "0": 0,
  "10": 10%,
  "20": 20%,
  "30": 30%,
  "40": 40%,
  "50": 50%,
  "60": 60%,
  "70": 70%,
  "80": 80%,
  "90": 90%,
  "100": 100%
);

@each $key, $value in $height-steps {
  .w-#{$key} {
    height: #{$value};
  }
}