.swiper-slide {
  width: 100%;
}
.swiper-wrapper {
  align-items: center !important;
  width: 100%;
  height: auto;
  position: relative;
}

.swiper__arrows {
  margin-top: 32px;
  position: relative;
  width: max-content;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border-radius: 50px;
  padding: 0 2px;
  margin: auto;
  margin-top: 32px;

  .swiper-button-prev,
  .swiper-button-next {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background-color: transparent;
    background-color: rgba($c-radiance-template, 1);
    border: 1px solid $c-radiance-template;

    color: $c-white;
    cursor: pointer;
    z-index: 1;
    position: static;
    margin: auto 0;

    &::after {
      color: $c-white;
      font-size: 18px;
      font-weight: 600;
      @media (width <= 991px) {
        font-size: 16px;
      }
    }

    @media (width <= 991px) {
      height: 40px;
      width: 40px;
    }
  }

  .swiper-button-prev {
    left: unset;
    content: none;
  }

  .swiper-button-next {
    right: unset;
    content: none;
  }
}

.swipper--pagination {
  .slider__controls {
    position: relative;
    z-index: 12;

    & .slider__pagination {
      text-align: center;
      // margin-top: 16px;
      left: 0;
      right: 0;
      margin: 16px auto 0 auto;
      transform: translateX(0%);

      & .swiper-pagination-bullet {
        width: 13px;
        height: 10px;
        display: inline-block;
        background: $c-main;
        opacity: 0.2;
        margin: 0 5px;
        -webkit-border-radius: 20px;
        border-radius: 20px;
        -webkit-transition: opacity 0.5s, background-color 0.5s, width 0.5s;
        -o-transition: opacity 0.5s, background-color 0.5s, width 0.5s;
        transition: opacity 0.5s, background-color 0.5s, width 0.5s;
        -webkit-transition-delay: 0.5s, 0.5s, 0s;
        -o-transition-delay: 0.5s, 0.5s, 0s;
        transition-delay: 0.5s, 0.5s, 0s;
      }
    }

    & .swiper-pagination-clickable .swiper-pagination-bullet {
      cursor: pointer;
    }

    & .slider__pagination .swiper-pagination-bullet-active {
      opacity: 1;
      background: $c-main;
      width: 30px;
      -webkit-transition-delay: 0s;
      -o-transition-delay: 0s;
      transition-delay: 0s;
    }
  }
}

.swiper-wrapper {
  height: auto !important;
}
